import React, { Component } from 'react';
import Input from './Input.js';
import SelectInput from './SelectInput.js';

const sidebarTranslations = {
    sidebarTitle: {
        fr: "Remplissez le formulaire",
        en: "Fill in the form"
    },
    firstnameInputLabel: {
        fr: "Prénom",
        en: "First Name"
    },
    lastnameInputLabel: {
        fr: "Nom",
        en: "Last Name"
    },
    jobtitleInputLabel: {
        fr: "Fonction",
        en: "Function"
    },
    emailInputLabel: {
        fr: "E-mail",
        en: "E-mail"
    },
    phoneInputLabel: {
        fr: "Téléphone",
        en: "Phone"
    },
    gsmInputLabel: {
        fr: "GSM",
        en: "GSM"
    },
    badgeInputLabel: {
        fr: "Badge",
        en: "Badge"
    },
    wishesInputLabel: {
        fr: "Wishes",
        en: "Wishes"
    },
    disclaimerInputLabel: {
        fr: "Disclaimer",
        en: "Disclaimer"
    },
}

class Sidebar extends Component {
    render() {
        return (
          <div className="sidebar">
            <h2 className="title">1. {sidebarTranslations.sidebarTitle[this.props.userLang]}</h2>
            <Input 
                name="firstname"
                label={sidebarTranslations.firstnameInputLabel[this.props.userLang]}
                value={this.props.person.firstname}
                onChange={(e) => this.props.onChange(e)}
                isRequired={true}
            />

            <Input 
                name="lastname"
                label={sidebarTranslations.lastnameInputLabel[this.props.userLang]}
                value={this.props.person.lastname}
                onChange={(e) => this.props.onChange(e)}
                isRequired={true}
            /> 
    
            <Input 
                name="jobtitle"
                label={sidebarTranslations.jobtitleInputLabel[this.props.userLang]}
                value={this.props.person.jobtitle}
                onChange={(e) => this.props.onChange(e)}
                isRequired={true}
            />

            <Input 
                name="email"
                label={sidebarTranslations.emailInputLabel[this.props.userLang]}
                value={this.props.person.email}
                onChange={(e) => this.props.onChange(e)}
                isRequired={true}
            />

            <Input 
                name="phone"
                label={sidebarTranslations.phoneInputLabel[this.props.userLang]}
                value={this.props.person.phone}
                onChange={(e) => this.props.onChange(e)}
                isRequired={true}
            />
            
            <Input 
                name="gsm"
                label={sidebarTranslations.gsmInputLabel[this.props.userLang]}
                value={this.props.person.gsm}
                onChange={(e) => this.props.onChange(e)}
            />

            {this.props.person.company === 'conceptfactory' && (
                <SelectInput 
                    options={[
                        {value: '', label: 'Aucun'},
                        {value: 'fg-partner', label: 'Google & Facebook Partner'},
                    ]} 
                    onChange={(e) => this.props.onChange(e)} 
                    name="badge" 
                    label={sidebarTranslations.badgeInputLabel[this.props.userLang]} value={this.props.person.badge} 
                />
            )}

            {(this.props.person.company === 'lexfield' || this.props.person.company === 'fidefield') && (
                <>
                <SelectInput
                    options={[
                        {value: '', label: 'No'},
                        {value: 'y', label: 'Yes'}
                    ]}
                    onChange={(e) => this.props.onChange(e)}
                    name="wishes"
                    label={sidebarTranslations.wishesInputLabel[this.props.userLang]} value={this.props.person.wishes}
                />

                <SelectInput
                    options={[
                        {value: '', label: 'No'},
                        {value: 'y', label: 'Yes'}
                    ]}
                    onChange={(e) => this.props.onChange(e)}
                    name="disclaimer"
                    label={sidebarTranslations.disclaimerInputLabel[this.props.userLang]} value={this.props.person.disclaimer}
                />
                </>
            )}
          </div>
        )
    }
}

export default Sidebar;