import React, { Component } from 'react';
import PreviewConceptfactory from './PreviewConceptfactory';
import PreviewInteract from './PreviewInteract';
import PreviewInnohub from './PreviewInnohub';
import PreviewSustain from './PreviewSustain';
import PreviewFidefield from './PreviewFidefield';
import PreviewLexfield from './PreviewLexfield';

const previewTransaltions = {
    previewTitle: {
        fr: "Visualisez un aperçu de votre signature",
        en: "View a preview of your signature"
    }
}

class Preview extends Component {
    render() {

        if (this.props.person.company === 'interact') {    
            return (
                <>
                    <h2 className="title">2. {previewTransaltions.previewTitle[this.props.userLang]}</h2>
                    <PreviewInteract person={this.props.person} />
                </>
            );
        } else if (this.props.person.company === 'conceptfactory') {
            return (
                <>
                    <h2 className="title">2. {previewTransaltions.previewTitle[this.props.userLang]}</h2>
                    <PreviewConceptfactory person={this.props.person} />
                </>
            );
        } else if (this.props.person.company === 'innohub') {
            return (
                <>
                    <h2 className="title">2. {previewTransaltions.previewTitle[this.props.userLang]}</h2>
                    <PreviewInnohub person={this.props.person} />
                </>
            );
        } else if (this.props.person.company === 'sustain') {
            return (
                <>
                    <h2 className="title">2. {previewTransaltions.previewTitle[this.props.userLang]}</h2>
                    <PreviewSustain person={this.props.person} />
                </>
            );
        } else if (this.props.person.company === 'lexfield') {
            return (
                <>
                    <h2 className="title">2. {previewTransaltions.previewTitle[this.props.userLang]}</h2>
                    <PreviewLexfield person={this.props.person} />
                </>
            );
        } else if (this.props.person.company === 'fidefield') {
            return (
                <>
                    <h2 className="title">2. {previewTransaltions.previewTitle[this.props.userLang]}</h2>
                    <PreviewFidefield person={this.props.person} />
                </>
            );
        }
    }
}

export default Preview;