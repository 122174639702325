import React, { Component } from 'react';
import InstructionsThunderbird from './InstructionsThunderbird';
import InstructionsOutlook from './InstructionsOutlook';
import InstructionsMail from './InstructionsMail';

class Instructions extends Component {
    render() {
        if (this.props.emailClient === 'thunderbird') {
            return (
                <InstructionsThunderbird userLang={this.props.userLang} onClose={() => this.props.onClose()}/>
            );
        } else if (this.props.emailClient === 'outlook') {
            return (
                <InstructionsOutlook userLang={this.props.userLang} onClose={() => this.props.onClose()}/>
            );
        } else if (this.props.emailClient === 'mail') {
            return (
                <InstructionsMail userLang={this.props.userLang} onClose={() => this.props.onClose()}/>
            );
        }
    }
}

export default Instructions;