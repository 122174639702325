import React from 'react';

import img02 from '../assets/img/instructions/mail/02.png';
import img03 from '../assets/img/instructions/mail/03.png';
import img04 from '../assets/img/instructions/mail/04.png';
import img05 from '../assets/img/instructions/mail/05.png';
import img06 from '../assets/img/instructions/mail/06.png';
import img07 from '../assets/img/instructions/mail/07.png';

const MailTranslations = {
    pageTitle: {
        fr: "Configuration d'une signature HTML sous Apple Mail",
        en: "Configuring an HTML signature in Apple Mail",
    },
    step1Title: {
        fr: "Téléchargez votre signature au format HTML (étape 3)",
        en: "Download your signature in HTML (step 3)",
    },
    step1Description: {
        fr: "Et placer ce fichier dans un emplacement facile d’accès.",
        en: "Save this file in a safe place, it should never be deleted.",
    },
    step2Title: {
        fr: "Préférences",
        en: "",
    },
    step2Description: {
        fr: <>Accédez aux préférences de Mail (⌘+,).<br/>Sous l’onglet <em>Signatures</em>, sélectionnez votre compte email dans la colonne de gauche.<br/>Sous la seconde colonne, cliquez sur le bouton <em>+</em>.</>,
        en: <>Go to Mail preferences. (⌘+,).<br/>Under the <em>Signatures</em> tab, select your email account in the left-hand column.<br/>Under the second column, click the <em>+</em> button.</>,
    },
    step3Title: {
        fr: "Création d’une signature",
        en: "Creating a signature",
    },
    step3Description: {
        fr: <>Votre signature apparait dans la seconde colonne.<br/></>,
        en: <>Your signature will appear in the second column<br/></>,
    },
    step4Title: {
        fr: "Modification de la signature",
        en: "Edit signature",
    },
    step4Description: {
        fr: <>Ouvrez votre signature créée précédemment dans la boite mail avec un éditeur de texte.<br/> Pour faciliter l’accès au dossier : Cliquer sur le bureau et dans Aller / Aller au dossier.../Users/username/Library/Mail/V9/MailData/Signatures/ (Le chemin peut varier légèrement d’une version à l’autre de Mac mais elle reste toujours dans un sous dossier Mail/.../Signatures)..</>,
        en: <>Open your previously created signature in the mailbox with a text editor.<br/> To facilitate access to the folder: Click on the desktop and in Go to / Go to folder.../Users/username/Library/Mail/V9/MailData/Signatures/ (The path may vary slightly from one version of Mac to another but it always remains in a Mail/.../Signatures subfolder).</>,
    },
    step5Title: {
        fr: "",
        en: "",
    },
    step5Description: {
        fr: "La signature est le fichier comportant une succession de chiffres et lettres.",
        en: "The signature is the file containing a succession of numbers and letters.",
    },
    step6Title: {
        fr: "Copier la signature",
        en: "Copy the signature",
    },
    step6Description: {
        fr: <>Gardez l’éditeur de texte ouvert avec votre signature. Ouvrez aussi votre signature créée avec Signator dans un navigateur Web et faites un clic droit &gt; Afficher le code source. <br/> Copier tout le texte présent dans la signature de Signator. <br/> Coller le contenu à la place du texte présent dans la signature créée dans mail sous les lignes ci-dessus (image).</>,
        en: <>Keep the text editor open with your signature. Also open your signature created with Signator in a Web browser window and right-click &gt; View source code. <br/> Copy all the text in the Signator signature. <br/> Paste the content in place of the text present in the signature created in mail under the lines above (image).</>,
    },
    step7Title: {
        fr: "Sauvegarder ce fichier",
        en: "Save this file",
    },
    step7Description: {
        fr: <>Sauvegarder ce fichier, sans oublier de vérrouiller celui-ci en cliquant sur le nom du fichier puis en cochant la case Verrouillé avant tout (image ci-dessus). <br/>Sans quoi votre signature sera réinitialisée au prochain lancement de Mail.<br />Le fichier créé avec Signator peut alors être supprimé sans risques. <br/> Relancer ensuite Mail.<br/></>,
        en: <>Save this file, without forgetting to lock it by clicking on the file name and then ticking the Locked box first (image above).<br/>Otherwise, your signature will be reset the next time Mail is launched.<br/>The file created with Signator can then be safely deleted.<br/>Then restart Mail.</>,
    },
}

function InstructionsMail(props) {
    return (
        <div className="overlayer overlayer--instruction">
            <div className="overlayer__inner">
                <div className="overlayer__button overlayer__button--close" onClick={() => props.onClose()}>x</div>
                <div className="overlayer__content">
                    <h1>{MailTranslations.pageTitle[props.userLang]}</h1>
                    <ol className="instructions">
                        <li>
                            <h3 className="title">{MailTranslations.step1Title[props.userLang]}</h3>
                            <span className="msg">{MailTranslations.step1Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{MailTranslations.step2Title[props.userLang]}</h3>
                            <img alt="" src={img02} />
                            <span className="msg">{MailTranslations.step2Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{MailTranslations.step3Title[props.userLang]}</h3>
                            <img alt="" src={img03} />
                            <span className="msg">{MailTranslations.step3Description[props.userLang]}<br/></span>
                        </li>
                        <li>
                            <h3 className="title">{MailTranslations.step4Title[props.userLang]}</h3>
                            <img alt="" src={img04} />
                            <span className="msg">{MailTranslations.step4Description[props.userLang]}</span>
                        </li>
                        <li>
                            <img alt="" src={img05} />
                            <span className="msg">{MailTranslations.step5Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{MailTranslations.step6Title[props.userLang]}</h3>
                            <img alt="" src={img06} />
                            <span className="msg">{MailTranslations.step6Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{MailTranslations.step7Title[props.userLang]}</h3>
                            <img alt="" src={img07} />
                            <span className="msg">{MailTranslations.step7Description[props.userLang]}</span>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default InstructionsMail;