import React, { Component } from 'react';

class SelectInput extends Component {
    render() {
        return (
            <div className={this.props.isRequired === true ? 'form-item form-item--select is-required' : 'form-item form-item--select'}>
                <label className="form-item__label" htmlFor={this.props.name}>{this.props.label}</label>
                <select 
                    className="form-item__input" id={this.props.name} name={this.props.name} 
                    onChange={(e) => this.props.onChange(e)}
                    required={this.props.isRequired}
                >
                    {this.props.options.map((option, index) => {
                        return (
                            <option key={index} value={option.value}>{option.label}</option>
                        );
                    })}
                </select>
            </div>
        );
    }
}

export default SelectInput;