import React, { Component } from 'react';
import Logo from './Logo';

class PreviewSustain extends Component {

    render() {
        return (
            <div className="preview">
                <div className="preview__container preview--sustain" style={{
                    fontSize: '10px', fontFamily: 'Courier, sans-serif', padding: 0, margin: 0, color: '#002f5e', width: '100%'
                }}>
                    <table border="0" cellPadding="0" cellSpacing="0">
                        <tr>
                            <td valign="top">
                                <table cellPadding="0" cellSpacing="0" border="0" width="340">
                                    <tr>
                                        <td>
                                            <a href="http://www.sustain.lu/"><img src={Logo('sustain')} alt="Sustain" title="Sustain" style={{border:0, width:'150px', height:'54px'}} width="150" height="54" id="sustain"/></a>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <table cellPadding="0" cellSpacing="0" border="0" width="340">
                                                <tr>
                                                    <td width="54">&nbsp;</td>
                                                    <td>
                                                        <p style={{color:'#6B8F68', fontSize:'15px', fontFamily:'Courier, sans-serif',margin:0,padding:0,fontWeight:'bold'}}>{this.props.person.firstname}&nbsp;{this.props.person.lastname}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td width="54">&nbsp;</td>
                                                    <td>
                                                        <p style={{color:'#6B8F68', fontSize:'12px',fontFamily:'Courier, sans-serif',margin:0,padding:0}}>{this.props.person.jobtitle}</p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="54">&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table cellPadding="0" cellSpacing="0" border="0" width="340">
                                                <tr>
                                                    <td width="54">&nbsp;</td>
                                                    <td>
                                                        <p style={{color:'#A0A5A9', fontSize:'10px',fontFamily:'Arial, sans-serif', margin:0,padding:0}}>21, rue de Pont-R&eacute;my<br/>L-2423&nbsp;Luxembourg</p>
                                                    </td>
                                                    <td valign="top">
                                                        <p style={{color:'#A0A5A9',fontSize:'10px',fontFamily:'Arial, sans-serif',margin:0,padding:0}}>T&eacute;l.:&nbsp;{this.props.person.phone}&nbsp;{this.props.person.gsm !== '' && <><br/>GSM.:&nbsp;{this.props.person.gsm}</>}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>											
                                                        <p style={{color: '#A0A5A9'}}><a href={'mailto:' + this.props.person.email } style={{color:'#A0A5A9', fontSize:'10px', fontFamily: 'Arial,sans-serif', textDecoration:'none',fontWeight:'bold'}}>{this.props.person.email}</a></p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td height="2" style={{lineHeight: '2px'}}>&nbsp;</td>
                                                    <td height="2" style={{borderBottom: '1px solid green', lineHeight:'2px'}}>&nbsp;</td>
                                                </tr>
                                                <tr>
                                                    <td>&nbsp;</td>
                                                    <td>
                                                        <p style={{color:'#003E67', fontFamily: 'Arial, sans-serif'}}>Strategic Partner of&nbsp;<img src={Logo('exxus')} alt="Exxus" title="Exxus" style={{border:0, width:'31px', height:'10px', verticalAlign: 'middle' }} width="31" height="10" id="exxus"/></p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        );
    }

}

export default PreviewSustain;