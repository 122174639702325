import React, { Component } from 'react';
import Logo from './Logo';

class PreviewInteract extends Component {
    render() {
        return (
            <div className="preview">
                <div className="preview__container preview--interact" style={{fontFamily: 'Arial,Helvetica,sans-serif',padding:0,margin:0,color:'#000000'}}>
                    <table border="0" cellPadding="0" cellSpacing="0" style={{padding: 0, margin: 0}}>
                        <tbody>
                            <tr style={{padding: 0, margin: 0}}>
                                <td valign="bottom" width="171" nowrap="nowrap" style={{ width: '171px', fontSize: '12px', fontWeight: 'bold', color: '#2E5986', verticalAlign: 'bottom', height: '24px', margin: 0, padding: 0}}>
                                    {this.props.person.firstname}&nbsp;{this.props.person.lastname}
                                    <p style={{display: 'block', margin: '3px 0 4px 0', fontSize: '11px', fontFamily: 'Arial', color: '#000000'}}>{this.props.person.jobtitle}</p>
                                </td>
                            </tr>
                            <tr style={{padding: 0, margin: 0}}>
                                <td className="txt" valign="top" width="171" nowrap="nowrap">
                                    <table border="0" cellPadding="0" cellSpacing="0" style={{padding: 0, margin: 0, fontSize: '11px'}} width="150">
                                        <tbody>
                                            <tr style={{padding: 0, margin: 0}}>
                                                <td valign="top" style={{height: '24px', margin: 0, padding: 0}}>
                                                    <a href={"https://www." + this.props.person.company + ".lu"} style={{display: 'block', width: '171px', border: 'none', cursor: 'pointer', verticalAlign: 'top', margin: 0, height: '55px'}} id="ialogo">
                                                        <img src={Logo(this.props.person.company)} alt={this.props.person.company} title={this.props.person.company} style={{border: 0, width: '128px', height: '40px'}} width="128" height="40" id="logo" />
                                                    </a>
                                                </td>
                                            </tr>
                                            <tr height="35">
                                                <td valign="top" style={{width: '45px', margin: 0, padding: 0, textAlign: 'left', fontSize: '11px', color: '#000000'}} colSpan="2">
                                                    <span x-apple-data-detectors="true" style={{ color: '#000000', textDecoration: 'none !important' }}>21, Rue de Pont-Rémy<br/>L-2423&nbsp;Luxembourg</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width: '45px', margin: 0, padding: 0, textAlign: 'left', fontSize: '11px', color: '#000000'}} colSpan="2">
                                                    <table border="0" cellPadding="0" cellSpacing="0" style={{padding: 0, margin: 0, fontSize: '11px'}}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{width: '45px', margin: 0, padding: 0, textAlign: 'left', fontSize: '11px', color: '#000000'}}>
                                                                    Tel:
                                                                </td>
                                                                <td>
                                                                    <span x-apple-data-detectors="true" style={{color: '#000000 !important', textDecoration: 'underline'}}>{this.props.person.phone}</span>
                                                                </td>
                                                            </tr>
                                                            {this.props.person.gsm !== '' && 
                                                                <tr>
                                                                    <td style={{width: '45px', margin: 0, padding: 0, textAlign: 'left', fontSize: '11px', color: '#000000'}}>
                                                                        GSM:
                                                                    </td>
                                                                    <td>
                                                                        <span x-apple-data-detectors="true" style={{color: '#000000 !important', textDecoration: 'underline'}}>{this.props.person.gsm}</span>
                                                                    </td>
                                                                </tr>
                                                            }
                                                            <tr>
                                                                <td style={{width: '45px', margin: 0, padding: 0, textAlign: 'left', fontSize: '11px', color: '#000000'}}>
                                                                    Fax:
                                                                </td>
                                                                <td>
                                                                    <span x-apple-data-detectors="true" style={{color: '#000000 !important', textDecoration: 'underline'}}>+352 26 19 64 68</span>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width: '45px', margin: 0, padding: 0, textAlign: 'left', fontSize: '11px', color: '#000000'}} colSpan="2">
                                                                    <a x-apple-data-detectors="true" href={'mailto:' + this.props.person.email} style={{fontSize:'11px', color: '#000000 !important'}}>{this.props.person.email}</a>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                                <td valign="top">
                                    <a href={"https://www." + this.props.person.company + ".lu"} style={{display: 'block', width: '171px', border: 'none', cursor: 'pointer', verticalAlign: 'top', margin: 0}} id="ialogo">
                                        <img src={Logo('25ans')} alt="25-ans" title="25-ans" style={{border: 0, width: '75px', height: '75px'}} id="logo-25" />
                                    </a>
                                </td>
                            </tr>
                            <tr style={{padding: 0, margin: 0}}>
                                <td colSpan="2">
                                    <table border="0" cellPadding="0" cellSpacing="0">
                                        <tbody>
                                            <tr>
                                                <td width="30" valign="bottom" style={{verticalAlign: 'bottom', height: '25px', padding: '4px 0 0 0', margin: 0}}>
                                                    <img src={Logo('tree')} alt="Think about environmental responsibility." title="Think about environmental responsibility." style={{border: 0, width: '19px', height: '19px'}} width="19" height="19" id="tree" />
                                                </td>
                                                <td valign="middle" nowrap="nowrap" style={{verticalAlign: 'middle', padding: '4px 0 0 0', fontFamily: 'Arial, Helvetica, sans-serif', fontSize:'10.5px', color:'#00AD64'}}>Please consider the environment before printing</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default PreviewInteract;