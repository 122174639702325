import React, { Component } from 'react';
import Logo from './Logo';

class PreviewConceptfactory extends Component {
    render() {
        return (
            <div className="preview">
                <div className="preview__container preview--conceptfactory" style={{fontSize: '10px',fontFamily: 'Arial, sans-serif',padding: 0,margin: 0,color: '#002f5e',
                    width: '100%'}}>
                    <table cellPadding="0" cellSpacing="0" border="0">
                        <tbody>
                            <tr>
                                <td valign="top">
                                    <table cellPadding="0" cellSpacing="0" border="0" width="271">
                                        <tbody>
                                            <tr height="59">
                                                <td height="59">
                                                    <p style={{color: '#002f5e', fontSize: '13px', fontFamily: 'Arial, sans-serif', textDecoration: 'none'}}><span>{this.props.person.firstname}&nbsp;<b style={{fontWeight: 800}}>{this.props.person.lastname}</b></span><br/><span style={{fontSize: '11px'}}>{this.props.person.jobtitle}</span></p>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table cellPadding="0" cellSpacing="0" border="0">
                                                        <tbody>
                                                            <tr>
                                                                <td width="140" style={{width:'140px'}}>
                                                                    <p height="58" style={{height: '58px', margin: 0}}>
                                                                        <img src={Logo(this.props.person.company)} alt="Concept Factory" title="Concept Factory" style={{border: 0, width: '125px', height: 58}} width="125" height="58" id="logotop" />&nbsp;&nbsp;&nbsp;&nbsp;
                                                                    </p>

                                                                    <p x-apple-data-detectors="true" style={{color:'#002f5e',fontSize:'10px',fontFamily:'Arial, sans-serif',margin:0,padding:0,marginBlockStart:'0.5em',marginTop:'0.5em'}}>21, rue de Pont-R&eacute;my<br/>L-2423&nbsp;Luxembourg</p>
                                                                    <p x-apple-data-detectors="true" style={{color:'#002f5e',fontSize:'10px',fontFamily:'Arial, sans-serif'}}>T.&nbsp;{this.props.person.phone}<br/>
                                                                    F.&nbsp;+352 48 49 66<br/>
                                                                    {this.props.person.gsm !== '' && (
                                                                        <>M.&nbsp;{this.props.person.gsm}<br/></>
                                                                    )}

                                                                    <a x-apple-data-detectors="true" href="http://www.conceptfactory.lu" style={{color: '#002f5e', fontSize: '10px', fontFamily: 'Arial, sans-serif', textDecoration: 'none'}}><b>www.{this.props.person.company}.lu</b></a></p>
                                                                    <p x-apple-data-detectors="true" style={{color:'#002f5e',  fontSize:'10px',fontFamily:'Arial,sans-serif', textDecoration:'none'}}><a x-apple-data-detectors="true" href={'mailto:' + this.props.person.email} style={{color: '#002f5e', fontSize: '10px',fontFamily:'Arial,sans-serif',textDecoration:'none'}}>{this.props.person.email}</a>
                                                                    </p>
                                                                </td>
                                                                <td valign="top">
                                                                    <p style={{height:'77px'}}><img src={Logo('25ans')} alt="25-ans" title="25-ans" style={{border:0, widht:'75px',height:'75px'}} width="75" height="75" id="logo-25" />&nbsp;&nbsp;&nbsp;&nbsp;</p>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>

                                            {this.props.person.badge !== '' && (
                                                <tr>
                                                    <td>
                                                        <a href="https://google.com/partners/agency?id=3036082782"><img src={Logo('ggp')} alt="Google Partner" title="Google Partner" style={{border:0,width:'87px',height:'90px'}} width="87" height="90" id="g-p" /></a>
                                                        <a href="https://www.facebook.com/business/marketing-partners"><img src={Logo('fbp')} alt="Facebook Marketing Partner" title="Facebook Marketing Partner" style={{border:0,width:'127px',height:'71px'}} width="127" height="71" id="f-p" /></a>
                                                    </td>
                                                </tr>
                                            )}

                                            <tr>
                                                <td>
                                                    <img src={Logo('socialLink')} alt="Stay connected!" title="Stay connected!" useMap="#bottom-net" style={{border:0, width:'271px',height:'58px'}} width="271" height="58" id="bottom-net" /><map name="bottom-net" id="bottom-net"><area shape="rect" coords="142,11,167,35" href="https://www.facebook.com/TheFactoryGroup/" alt=""/><area shape="rect" coords="167,11,190,35" href="https://www.instagram.com/concept_factory/" alt=""/><area shape="rect" coords="190,11,213,35" href="https://twitter.com/thefactorygroup" alt=""/><area shape="rect" coords="213,11,236,35" href="https://www.youtube.com/user/interactluxembourg" alt=""/></map>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default PreviewConceptfactory;