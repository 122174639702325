import React, { Component } from 'react';

class EmailClient extends Component {
    render() {
        return (
            <div className="email-client" onClick={() => this.props.onClick()}>
                <img className="email-client__icon" src={this.props.icon} alt={this.props.name} />
                <span className="email-client__name">{this.props.name}</span>
            </div>
        );
    }
}

export default EmailClient;