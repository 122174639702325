import React, { Component } from 'react';
import Input from './Input';

class Login extends Component {
    render() {
        return (
            <div>
                <Input name="login" label="Login" isRequired onChange={(e) => this.props.onChange(e)} />
                <Input name="password" label="Password" isRequired isPassword onChange={(e) => this.props.onChange(e)} />
                <button type="submit" onClick={(e) => this.props.onSubmit(e)}>Login</button>
            </div>
        );
    }
}

export default Login;