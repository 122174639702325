import React, { Component } from 'react';

class Input extends Component {
    render() {
        return (
            <div className={this.props.isRequired === true ? 'form-item is-required' : 'form-item'}>
                <label className="form-item__label" htmlFor={this.props.name}>{this.props.label}</label>
                <input 
                    className="form-item__input" id={this.props.name} type={this.props.isPassword ? "password" : "text"} name={this.props.name} 
                    value={this.props.value} 
                    onChange={(e) => this.props.onChange(e)}
                    required={this.props.isRequired}
                />
            </div>
        );
    }
}

export default Input;