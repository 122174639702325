import React, { Component } from 'react';
import Logo from './Logo';

class PreviewInnohub extends Component {
    render() {
        return (
            <div className="preview">
                <div className="preview__container preview--innohub" style={{fontSize: '10px', fontFamily: 'Courier, sans-serif', padding:0, margin:0, color: '#002f5e', width:'100%'}}>
                    <table border="0" cellPadding="0" cellSpacing="0">
                        <tr>
                            <td valign="top">
                                <table cellPadding="0" cellSpacing="0" border="0" width="150">
                                    <tr>
                                        <td>
                                            <a href="http://www.innohub.lu">
                                                <img src={Logo('innohub')} alt="Innohub" title="Innohub" style={{border: 0, width: '150px', height: '60px'}} width="150" height="60" id="innohub" />
                                            </a>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style={{borderBottom: '2px solid #6FBAE9'}}>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table cellPadding="0" cellSpacing="0" border="0" width="150">
                                                <tr>
                                                    <td>
                                                        <p style={{fontSize: '15px', fontFamily: 'Lucida, sans-serif', margin: 0, padding: 0, fontWeight: 'bold'}}>{(this.props.person.firstname).toUpperCase()}&nbsp;{(this.props.person.lastname).toUpperCase()}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <p style={{color:'#6FBAE9', fontSize: '12px', fontFamily: 'Lucida, sans-serif', margin: 0, padding: 0}}>{(this.props.person.jobtitle).toUpperCase()}</p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style={{color:'#003E62', fontSize:'10px', fontFamily:'Arial, sans-serif', margin:0, padding:0}}>21, rue de Pont-R&eacute;my<br/>L-2423&nbsp;Luxembourg</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>&nbsp;</td>
                                    </tr>
                                    <tr>
                                        <td>
                                        <p style={{color:'#003E62', fontFamily:'Arial, sans-serif', margin:0, padding:0}}>
                                            T&eacute;l.:&nbsp;{this.props.person.phone}
                                            {this.props.person.gsm !== '' && (<><br/>GSM.:&nbsp;{this.props.person.gsm}</>)}

                                            </p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p style={{color: '#003e62'}}><a href={ 'mailto:' + this.props.person.email } style={{color: '#003e62', fontSize: '10px', fontFamily: 'Arial, sans-serif', textDecoration: 'none', fontWeight: 'bold' }}>{this.props.person.email}</a></p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td height="2" style={{borderBottom: '2px solid #6fbae9', lineHeight: '2px'}}>&nbsp;</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        );
    }
}

export default PreviewInnohub;