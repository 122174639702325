
import React from 'react';
import img08 from '../assets/img/instructions/outlook/08.gif';
import img07 from '../assets/img/instructions/outlook/07.gif';
import img06 from '../assets/img/instructions/outlook/06.gif';
import img05 from '../assets/img/instructions/outlook/05.gif';
import img04 from '../assets/img/instructions/outlook/04.gif';
import img03 from '../assets/img/instructions/outlook/03.gif';
import img02 from '../assets/img/instructions/outlook/02.gif';
import img01 from '../assets/img/instructions/outlook/01.gif';

const OutlookTranslations = {
    pageTitle: {
        fr: "Configuration d'une signature HTML sous Microsoft Outlook",
        en: "Configuring an HTML signature in Microsoft Outlook"
    },
    step1Title: {
        fr: "Téléchargez l’archive au format ZIP (étape 3)",
        en: "Download the archive in ZIP format (step 3)"
    },
    step1Description: {
        fr: "Décompressez cette archive sur votre ordinateur.<br/>Elle contient tous les fichiers nécessaires à la création de votre signature HTML.",
        en: ""
    },
    step2Title: {
        fr: "Créer la signature dans Outlook",
        en: "Create the signature in Outlook"
    },
    step2Description: {
        fr: <>Dans le menu <em>File</em>, sélectionnez <em>Options</em></>,
        en: <>From the <em>File</em> menu, select <em>Options</em></>
    },
    step3Title: {
        fr: "Outlook Options",
        en: "Outlook Options"
    },
    step3Description: {
        fr: <>Dans la fenêtre <em>Outlook Options</em> qui vient de s'ouvrir, selectionnez dans la navigation de gauche <em>Mail</em></>,
        en: <>In the <em>Outlook Options</em> window that has just opened, select in the left navigation <em>Mail</em>"</>
    },
    step4Title: {
        fr: "Creez ou modifier les signatures pour les message",
        en: " Create or edit signatures for messages"
    },
    step4Description: {
        fr: <>Cliquez sur le bouton <em>Signatures...</em></>,
        en: <>Click the <em>Signatures...</em> button</>
    },
    step5Title: {
        fr: "Ajouter une nouvelle Signature",
        en: "Add a new Signature"
    },
    step5Description: {
        fr: <>Dans la nouvelle fenêtre <em>Signatures and Stationery</em> cliquez sur le bouton <em>New</em><br/>Entrez le nom <em>Conceptfactory</em> dans la boite de dialogue.<br/>(Il est important de respecter ce nom, afin d'éviter de devoir renommer vos fichiers par la suite)<br/>Sauvegarder en appuyant sur <em>Save</em></>,
        en: <>In the new <em>Signatures and Stationery</em> window click on the <em>New</em><br/> button. Enter the name <em>Conceptfactory</em> in the dialog box.<br/ >(It is important to respect this name, in order to avoid having to rename your files afterwards)<br/>Save by pressing <em>Save</em></>
    },
    step6Title: {
        fr: "Utiliser votre propre fichier HTML",
        en: "Use your own HTML file"
    },
    step6Description: {
        fr: <>Rendez-vous dans l’un de ces dossiers (suivant votre système d’exploitation) :<br/><em>Windows 8/Windows 7/Windows Vista : </em>C:\Users\<em>nom-d-utilisateur</em>\AppData\Roaming\Microsoft\Signatures\ <br/><em>Windows XP : </em>C:\Documents and Settings\<em>nom-d-utilisateur</em>\Application Data\Microsoft\Signatures\ <br/><br/><em>Note: </em> Pour un accès rapide, copiez-collez le chemin entier dans la barre d’adresse de l’explorateur Windows. (en remplaçant <em>nom-d-utilisateur</em> par votre propre nom d’utilisateur.)<br/>Si la barre d’adresse n’est pas présente, rendez vous dans le menu <em>Affichage → Barre d’outils → Barre d’adresse</em>.</>,
        en: <>Go to one of these folders (depending on your operating system):<br/><em>Windows 8/Windows 7/Windows Vista: </em>C:\Users\<em>d-name -user</em>\AppData\Roaming\Microsoft\Signatures\ <br/><em>Windows XP: </em>C:\Documents and Settings\<em>username</em>\Application Data\Microsoft\Signatures\ <br/><br/><em>Note: </em> For quick access, copy-paste the entire path into the Windows Explorer address bar. (replacing <em>username</em> with your own username.)<br/>If the address bar is not present, go to the menu <em>View → Toolbar → Address Bar</em>.</>
    },
    step7Title: {
        fr: "Remplacer les fichiers",
        en: "Replace files"
    },
    step7Description: {
        fr: <>Glissez-déposez les fichiers téléchargés dans le dossier correspondant.<br/>Vérifiez à utiliser le même nom de fichier que les fichiers déja présents.</>,
        en: <>Drag and drop the downloaded files into the corresponding folder.<br/>Make sure to use the same file name as the files already present.</>
    },
    step8Title: {
        fr: "Confirmer le remplacement des fichiers",
        en: "Confirm file replacement"
    },
    step8Description: {
        fr: <>Une alerte vous demande de confirmer le remplacement des fichiers.<br/>Cliquez sur <em>Oui</em> afin de remplacer les fichiers.</>,
        en: <>An alert asks you to confirm the replacement of the files.<br/>Click on <em>Yes</em> in order to replace the files.</>
    },
    step9Title: {
        fr: "Sélectionnez votre signature par défaut",
        en: "Select your default signature"
    },
    step9Description: {
        fr: <>Dans le cadre <em>Choisir une signature par défaut</em>, sélectionnez votre signature dans la liste déroulante, afin qu’elle apparaisse en temps voulu.<br/>Votre signature est prête.</>,
        en: <>In the <em>Choose a default signature</em> frame, select your signature from the drop-down list, so that it appears in due time.<br/>Your signature is ready.</>
    },
}

function InstructionsOutlook(props) {
    return (
        <div className="overlayer overlayer--instruction">
            <div className="overlayer__inner">
                <div className="overlayer__button overlayer__button--close" onClick={() => props.onClose()}>x</div>
                <div className="overlayer__content">
                <h1>{OutlookTranslations.pageTitle[props.userLang]}</h1>
                    <ol className="instructions">
                        <li>
                            <h3 className="title">{OutlookTranslations.step1Title[props.userLang]}</h3>
                            <span className="msg">{OutlookTranslations.step1Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{OutlookTranslations.step2Title[props.userLang]}</h3>
                            <img alt="" src={img01} />
                            <span className="msg">{OutlookTranslations.step2Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{OutlookTranslations.step3Title[props.userLang]}</h3>
                            <img alt="" src={img02} />
                            <span className="msg">{OutlookTranslations.step3Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{OutlookTranslations.step4Title[props.userLang]}</h3>
                            <img alt="" src={img03} />
                            <span class="msg">{OutlookTranslations.step4Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 class="title">{OutlookTranslations.step5Title[props.userLang]}</h3>
                            <img alt="" src={img04} />
                            <span class="msg">{OutlookTranslations.step5Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{OutlookTranslations.step6Title[props.userLang]}</h3>
                            <img alt="" src={img05} />
                            <span className="msg">{OutlookTranslations.step6Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{OutlookTranslations.step7Title[props.userLang]}</h3>
                            <img alt="" src={img06} />
                            <span className="msg">{OutlookTranslations.step7Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{OutlookTranslations.step8Title[props.userLang]}</h3>
                            <img alt="" src={img07} />
                            <span className="msg">{OutlookTranslations.step8Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{OutlookTranslations.step9Title[props.userLang]}</h3>
                            <img alt="" src={img08} />
                            <span className="msg">{OutlookTranslations.step9Description[props.userLang]}</span>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default InstructionsOutlook;