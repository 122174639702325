import React, { Component } from 'react';
import Logo from './Logo';

class PreviewFidefield extends Component {
    render() {
        return (
            <div className="preview">
                <div className="preview__container preview--conceptfactory">
                    {this.props.person.wishes !== '' && (
                        <table border="0" cellpadding="0" cellspacing="0" style={{fontFamily:'Arial, sans-serif',fontSize:'13px',lineHeight:'18px',color:'#676B68',fontWeight:700,fontStyle:'italic'}}>
                            <tbody>
                                <tr>
                                    <td height="45" valign="top">Wishing you a joyful holiday season<br />& every success in the coming year</td>
                                </tr>
                            </tbody>
                        </table>
                    )}

                    <table border="0" cellPadding="0" cellSpacing="0" style={{ fontFamily:'Arial, sans-serif', fontSize: '12px', lineHeight: '18px', color: '#676B68', borderBottom: '3px solid #006d81', borderTop: '3px solid #006d81'}}>
                        <tbody>
                            <tr>
                                <td align="left" valign="top" width="200" nowrap="true">
                                    <br/>
                                    <nobr><span style={{fontSize:'14px', color:'#006d81'}}><b>{this.props.person.firstname} {this.props.person.lastname}</b></span></nobr><br/>
                                    <nobr><b>{this.props.person.jobtitle.split('|').map(function(job, i){
                                            if (i > 0) {
                                                return <span key={'job'+i}><br/><span>{job}</span></span>
                                            } else {
                                                return <span key={'job'+i}><span >{job}</span></span>
                                            }
                                        })}</b></nobr><br/>
                                    <a href={'mailto:' + this.props.person.email} style={{color:'#006d81',textDecoration:'none'}}>
                                        <font color="#676B68">
                                            <span style={{textDecoration:'none'}}>{this.props.person.email}</span>
                                        </font>
                                    </a>
                                    {this.props.person.phone !== '' && (<><br/><nobr><span><b>D.</b> {this.props.person.phone}</span></nobr></>)}
                                    {this.props.person.gsm !== '' && (<><br/><nobr><span><b>M.</b> {this.props.person.gsm}</span></nobr></>)}
                                    <br/>
                                </td>
                                <td align="left" valign="top" width="10" nowrap="true">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                <td align="left" valign="top" width="140" nowrap="true">
                                    <br/>
                                    <span><b>FideField S.a&#768; r.l.</b></span><br/>
                                    <span><nobr>18, rue Michel Rodange</nobr></span><br/>
                                    <span><nobr>L-2430 Luxembourg</nobr></span><br/>
                                    <span><b>T.</b> +352 281 336</span><br/>
                                    <span><b>F.</b> +352 270 482 49</span><br/>
                                    <span style={{display:'inline-block'}}>
                                        <b>
                                            <a style={{textDecoration: 'none !important',color:'#676B68 !important'}} href="http://www.fidefield.com">
                                                <font color="#676B68">
                                                    <strong style={{fontWeight: 'bold',textDecoration: 'none !important'}}>
                                                        <span style={{textDecoration:'none',color:'#676B68'}}>
                                                            www.fidefield.com
                                                        </span>
                                                    </strong>
                                                </font>
                                            </a>
                                        </b>
                                    </span>
                                    <br/><br/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <span style={{backgroundColor: 'white'}}><img src={Logo(this.props.person.company)} alt="FideField" title="FideField" style={{border:0,width:'2in',height:'0.58in',display:'block',minWidth:'180px'}} width="2in" height="0.58in" id="fidefield"/></span>

                    {this.props.person.disclaimer !== '' && (
                        <>
                        <br/><br/>

                        <table border="0" cellPadding="0" cellSpacing="0" style={{ fontFamily:'Arial, sans-serif', textAlign: 'justify', fontSize:'11px', lineHeight:'16px', color:'#676B68'
                        }}>
                            <tbody>
                                <tr>
                                    <td align="justify">
                                        <span style={{textAlign: 'justify'}}>
                                            <br/>
                                            <b>CONFIDENTIALITY NOTICE.</b> This email and any attachments are confidential and may contain confidential and/or privileged information intended solely for the addressees. Any use of this email and/or its attachments not in accord with its purpose, or any dissemination or disclosure, either whole or partial, is prohibited, unless formally and specifically authorized by FideField. If you receive this email in error, please delete it and any attachments and immediately notify FideField by return email. FideField will not be liable for the information in the email or attachments if anything is altered, changed or falsified.
                                            <b>IT DISCLAIMER.</b> Although emails and any attachments are believed to be free of any virus or any other defect, which might affect any computer or IT system into which they are received and opened, it is the responsibility of the recipient to ensure they are virus free and no responsibility is accepted by FideField for any loss or damage arising in any way from receipt or use thereof.
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
        </>
                    )}
                </div>
            </div>
        );
    }
}

export default PreviewFidefield;