import React, { Component } from 'react';

const downloadTranslations = {
    fr: "Télécharger votre signature",
    en: "Download your signature"
}

class Download extends Component {
    render() {
        return (
            <div className="download">
                <h2 className="title">3. {downloadTranslations[this.props.userLang]}</h2>
                <button id="download" onClick={(e) => this.props.onClick(e)}>Download</button>
            </div>
        );
    }
}

export default Download;