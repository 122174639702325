import React from 'react';
import etape3 from '../assets/img/instructions/thunderbird/etape3.gif';
import etape4 from '../assets/img/instructions/thunderbird/etape4.gif';
import etape5 from '../assets/img/instructions/thunderbird/etape5.gif';

const ThunderbirdTranslations = {
    pageTitle: {
        fr: "Configuration d'une signature HTML sous Mozilla Thunderbird",
        en: "Configuring an HTML signature in Mozilla Thunderbird"
    },
    step1Title: {
        fr: "Téléchargez votre signature au format HTML (étape 3)",
        en: "Download your signature in HTML (step 3)",
    },
    step1Description: {
        fr: "Conservez ce fichier en lieu sûr, il ne devra jamais être supprimé.",
        en: "Save this file in a safe place, it should never be deleted.",
    },
    step2Title: {
        fr: "Paramètres des comptes",
        en: "Account Settings",
    },
    step2Description: {
        fr: "Allez dans Outils &gt; Paramètres des comptes",
        en: "Go to Tools &gt; Account Settings",
    },
    step3Title: {
        fr: "Apposer la signature",
        en: "Add signature",
    },
    step3Description: {
        fr: "Cochez la case Apposer la signature à partir d'un fichier (texte, HTML ou image)",
        en: "Check the box Add signature from a file (text, HTML or image)",
    },
    step4Title: {
        fr: "Choix du fichier HTML de la signature",
        en: "Choose File HTML signature",
    },
    step4Description: {
        fr: "Sélectionnez le fichier HTML de la signature sur votre ordinateur",
        en: "Select the HTML file for the signature on your computer",
    },
    step5Title: {
        fr: "Validation",
        en: "Validation",
    },
    step5Description: {
        fr: "Validez avec le bouton OK. Votre signature est prête.",
        en: "Confirm with OK. Your signature is ready.",
    },
}

function InstructionsThunderbird(props) {
    return (
        <div className="overlayer overlayer--instruction">
            <div className="overlayer__inner">
                <div className="overlayer__button overlayer__button--close" onClick={() => props.onClose()}>x</div>
                <div className="overlayer__content">
                    <h1>{ThunderbirdTranslations.pageTitle[props.userLang]}</h1>
                    <ol className="instructions">
                        <li>
                            <h3 className="title">{ThunderbirdTranslations.step1Title[props.userLang]}</h3>
                            <span className="msg">{ThunderbirdTranslations.step1Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{ThunderbirdTranslations.step2Title[props.userLang]}</h3>
                            <img alt=""src={etape3} />
                            <span className="msg">{ThunderbirdTranslations.step2Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{ThunderbirdTranslations.step3Title[props.userLang]}</h3>
                            <img alt=""src={etape4} />
                            <span className="msg">{ThunderbirdTranslations.step3Description[props.userLang]}</span>
                        </li>
                        <li>
                            <h3 className="title">{ThunderbirdTranslations.step4Title[props.userLang]}</h3>
                            <img alt=""src={etape5}/>
                            <span className="msg">{ThunderbirdTranslations.step4Description[props.userLang]}</span>
                        </li>
                        <li>
                        <h3 className="title">{ThunderbirdTranslations.step5Title[props.userLang]}</h3>
                        <span className="msg">{ThunderbirdTranslations.step5Description[props.userLang]}</span>
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    );
}

export default InstructionsThunderbird;