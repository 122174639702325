import React, { Component } from 'react';

class Logout extends Component {
    render() {
        return (
            <div className="logout" onClick={(e) => this.props.onClick(e)}>
                {this.props.login.usr} [x]
            </div>
        );
    }
}

export default Logout;